<template>
  <div>
    <v-dialog max-width="600" v-model="NewFieldDialog">
      <v-card>
        <v-card-title class="overline">
          {{ NewField.Type }}
        </v-card-title>
        <v-card-text v-html="NewField.Description"> </v-card-text>
        <FieldConfig
          @UpdateField="UpdateField"
          @Onepropemit="Onepropemit"
          @UpdateRelated="UpdateRelated"
          :CurrentEntity="CurrentEntity"
          @RefreshField="RefreshField"
          v-if="!RefreshingNewField"
          :SystemEntities="SystemEntities"
          :field="NewField"
          :NewField="AddingNewField"
          :System="System"
        />
        <v-card-text>
          <SingleTabFieldsSection
            @UpdateEditableField="UpdateEditableField"
            :SystemEntities="SystemEntities"
            :RelatedObj="RelatedObj"
            :sectionindex="''"
            :tabindex="''"
            :SampleOnly="true"
            :System="System"
            :PrimaryFieldName="''"
            :SingleFormEditing="false"
            :CurrentEntity="CurrentEntity"
            :Record="SampleRecord"
            :AdditionalSaveMethod="AdditionalSaveMethod"
            :section="''"
            :tab="''"
            :Fields="[NewField]"
            :AppisDarkMode="AppisDarkMode"
            :CanEdit="true"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelNewFieldDialog()"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="AddField(NewField)">
            {{ AddingNewField ? "Add Field" : "Update Field" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title class="overline">
        {{ NewTableOption.OptionType }}
      </v-card-title>
      <v-card-text v-html="NewTableOption.Description"> </v-card-text>
      <v-card-text>
        <v-list dense>
          <v-list-item>
            <v-text-field
              :filled="NewTableOption.id"
              :readonly="NewTableOption.id"
              v-model="NewTableOption.Name"
              label="Name"
            />
          </v-list-item>
          <v-list-item>
            <v-text-field
              v-model="NewTableOption.DisplayName"
              label="DisplayName"
            />
          </v-list-item>
        </v-list>
        <v-list
          dense
          v-if="
            NewTableOption.OptionType === 'Document Register' ||
            NewTableOption.OptionType === 'Gallery'
          "
        >
          <v-list-item>
            <v-select
              v-model="NewTableOption.CreatePermission"
              label="Create"
              :items="['Inherited', 'Custom Role', 'Admin']"
            />
          </v-list-item>
          <v-list-item>
            <v-select
              v-model="NewTableOption.GetPermission"
              label="Get (Open)"
              :items="['Inherited', 'Custom Role', 'Admin']"
            />
          </v-list-item>
          <v-list-item>
            <v-select
              v-model="NewTableOption.ListPermission"
              label="See the list"
              :items="['Inherited', 'Custom Role', 'Admin']"
            />
          </v-list-item>
          <v-list-item>
            <v-select
              v-model="NewTableOption.UpdatePermission"
              label="Update (e.g. change Class, or Table)"
              :items="['Inherited', 'Custom Role', 'Admin']"
            />
          </v-list-item>
          <v-list-item>
            <v-select
              v-model="NewTableOption.DeletePermission"
              label="Delete"
              :items="['Inherited', 'Custom Role', 'Admin']"
            />
          </v-list-item>
          <v-list-item
            dense
            v-if="NewTableOption.OptionType === 'Document Register'"
          >
            <v-btn
              @click="Onepropemit('ActivateOptionSetDialog', NewTableOption)"
              >Options</v-btn
            >
          </v-list-item>
        </v-list>
        <v-list v-if="NewTableOption.OptionType === 'SubCollection'">
          <v-list-item>
            <v-chip-group active-class="primary--text" column>
              <v-chip
                @click="ActivateNewFieldDialog(field, true)"
                small
                v-for="field in FieldTypesSearched"
                :key="field.itemObjKey"
              >
                {{ field.Type }}
              </v-chip>
            </v-chip-group>
          </v-list-item>
          <v-list-item>
            <v-dialog v-model="importdialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="green" dark class="mb-2" v-on="on">Import</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Import Dialog</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12">
                      <v-text-field
                        v-model="ImportFileSessionName"
                        label="Name"
                      ></v-text-field>
                      <input
                        v-if="!ImportFile"
                        type="file"
                        @change="onImportfileSelected($event)"
                        ref="ImportFileinputter"
                        id="fileUpload"
                      />
                      <v-btn @click="DownloadFieldsImportTemplate()"
                        >Download Template</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeImport()"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="saveImport()"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list-item>
          <v-list-item>
            <!-- {{NewTableOption.Headers}} -->
            <!-- <v-autocomplete return-object multiple chips v-model="NewTableOption.Headers" :items="NewTableOption.Headers" label="Headers" item-text="Name" readonly>
                            <template v-slot:selection="data">
                                 -->
            <v-chip-group active-class="primary--text" column>
              <v-chip
                @click="ActivateNewFieldDialog(field, true)"
                small
                v-for="field in NewTableOption.Headers"
                :key="field.itemObjKey"
              >
                {{ field.Name }}
              </v-chip>
            </v-chip-group>
            <!--                                 
                            </template>
                        </v-autocomplete> -->
          </v-list-item>
          <v-list-item>
            <v-select
              @change="NoPropsemit('ResetTableOptionDialog')"
              v-model="NewTableOption.Type"
              label="Type"
              :items="['Lookup', 'Standard']"
            />
          </v-list-item>
          <!-- NewTableOption.Type {{NewTableOption.Type}} -->
          <v-list-item v-if="NewTableOption.Type === 'Lookup'">
            <!-- @change="UpdateRelated(field,field.RelatedBuildID)" -->
            <v-select
              v-model="NewTableOption.LookupBuilds"
              :items="LookupEntitiesSubCols"
              class="mx-2"
              item-text="id"
              label="Lookup Builds"
              multiple
              return-object
              @change="NoPropsemit('ResetTableOptionDialog')"
            ></v-select>
          </v-list-item>
          <v-list v-if="NewTableOption.Type === 'Lookup'">
            <v-list-item class="black">
              <v-list-item-content class="white--text">
                Related DataSet
              </v-list-item-content>
              <v-list-item-content
                class="white--text"
                v-for="header in NewTableOption.Headers"
                :key="header.itemObjKey"
              >
                {{ header.Name }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="build in NewTableOption.LookupBuilds"
              :key="build.itemObjKey"
            >
              <v-list-item-content>
                {{ build.tempname }}
              </v-list-item-content>
              <v-list-item-content
                v-for="(header, headerindex) in NewTableOption.Headers"
                :key="header.itemObjKey"
              >
                <v-autocomplete
                  @change="
                    CheckForPrimary(
                      NewTableOption,
                      build.LinkedFields[header.value],
                      header,
                      headerindex
                    )
                  "
                  dense
                  :label="header.text"
                  v-model="build.LinkedFields[header.value]"
                  :items="build.AllFields"
                  item-text="DisplayName"
                  return-object
                ></v-autocomplete>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list-item v-if="NewTableOption.Type === 'Lookup'">
            <v-switch
              v-model="NewTableOption.Lookup_Once_Only"
              label="Records can link Once Only"
            />
          </v-list-item>
          <v-list-item>
            <v-switch
              v-model="NewTableOption.ConfigValueasDocID"
              label="ConfigValueasDocID"
            />
          </v-list-item>
          <v-list-item v-if="HasStoreProducts">
            <v-switch
              v-model="NewTableOption.Warehouse_Consumption"
              label="Warehouse Consumption"
            />
          </v-list-item>
          <v-list-item v-if="NewTableOption.Warehouse_Consumption">
            <v-switch
              v-model="NewTableOption.Available_Stock_Only"
              label="Available_Stock_Only"
            />
          </v-list-item>
          <v-list-item v-if="NewTableOption.ConfigValueasDocID">
            <v-select
              v-model="NewTableOption.DocIDProp"
              :items="NewTableOption.Headers"
              class="mx-2"
              item-text="Name"
              label="DocIDProp"
            ></v-select>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-text
        v-if="
          SampleTableOption.OptionType === 'SubCollection' &&
          SampleTableOption.Entityid
        "
      >
        <!-- <v-btn @click="NoPropsemit('ResetTableOptionDialog')">Refresh</v-btn> -->
        <TableEmbedTab
          :PageHeadersFont="PageHeadersFont"
          :PageContentFont="PageContentFont"
          :PageHeadersFontSize="PageHeadersFontSize"
          :i="index"
          :IsElement="true"
          :tabindex="''"
          :rowindex="rowindex"
          :SystemEntities="SystemEntities"
          :RelatedObj="RelatedObj"
          :EntityCollectionRef="EntityCollectionRef"
          :EntityDataRef="EntityDataRef"
          :tab="SampleTableOption"
          @OpenDialog="OpenDialog"
          :ParentTab="''"
          :DynamicWikiData="SampleRecord"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="NoPropsemit('CancelTableOptionDialog')"> Cancel </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="Onepropemit('AddTableOption', NewTableOption)">
          {{
            NewTableOption.id
              ? "Update " + NewTableOption.id
              : "Add " + NewTableOption.MultipleFileUploadType
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import firebase from "firebase";
import db from "@/main";
import axios from "axios";
import format from "date-fns/format";
import FieldConfig from "@/components/Database/FieldConfig";
import TableEmbedTab from "@/components/WebPages/RenderComponents/TableEmbedTab";
import SingleTabFieldsSection from "@/components/Database/SingleTabFieldsSection";
export default {
  props: ["System", "SystemEntities", "NewTableOption", "SampleTableOption",'CurrentEntity'],
  components: { FieldConfig, TableEmbedTab, SingleTabFieldsSection },
  data() {
    return {
      NewField: "",
      NewFieldDialog: false,
      AddingNewField: false,
      RefreshingNewField: false,
    };
  },
  computed: {
    HasStoreProducts(){
      return this.NewTableOption && this.NewTableOption.LookupBuilds && this.NewTableOption.LookupBuilds.find(obj => obj.Entity_Type && obj.Entity_Type.Name === 'Store Product')
    },
    LookupEntitiesSubCols() {
      return this.SystemEntities.map((entity) => {
        let entityobject = {
          id: entity.id,
          AllFields: entity.AllFields,
          Headers: entity.AllFields.filter((field) => {
            return field.IsHeader;
          }).map((field) => {
            if (!field.text) {
              field.text = field.DisplayName;
              field.value = field.Name;
            }
            return field;
          }),
          dataseticon: entity.Table_Icon,
          identifier: entity.RecordPrimaryField.Name,
          recordprimaryfield: entity.RecordPrimaryField,
          tempname: entity.SingleName,
          temppluralname: entity.DisplayName,
          single: entity.SingleName.split(" ").join(""),
          SubCollections: entity.SubCollections.map((subcol) => {
            return subcol.id;
          }),
          Entity_Type: entity.Entity_Type,
          LinkedFields: {},
        };
        return entityobject;
      });
    },
    userLoggedIn() {
      return this.$store.getters.user;
    },
    userIsAdmin() {
      return this.$store.state.IsAdmin;
    },
    WindowHeight() {
      return window.innerHeight;
    },
    SocialItemInteractMethod() {
      return this.$store.state.SocialItemInteractMethod;
    },
    SocialItemInteractMethodProp() {
      return this.$store.state.SocialItemInteractMethodProp;
    },
  },
  watch: {
    SocialItemInteractMethod(v) {
      let acceptedmethods = [];
      if (v && acceptedmethods.includes(v)) {
        if (this.SocialItemInteractMethodProp) {
          this[this.SocialItemInteractMethod](
            this.SocialItemInteractMethodProp
          );
          if (this.SocialItemInteractMethod === "ActivateSelectedElement") {
            this.SingleRecordNavMenuItem = "";
          }
          this.$store.commit("SetSocialItemInteractMethodProp", "");
          this.$store.commit("SetSocialItemInteractMethod", "");
        } else {
          this[this.SocialItemInteractMethod]();
          this.$store.commit("SetSocialItemInteractMethodProp", "");
          this.$store.commit("SetSocialItemInteractMethod", "");
        }
      }
    },
  },
  created() {},
  methods: {
    NoPropsemit(MethodName) {
      this.$emit("NoPropsemit", MethodName);
    },
    RefreshField(field) {
      this.RefreshingNewField = true;
      setTimeout(() => {
        this.RefreshingNewField = false;
      }, 100);
    },
    Onepropemit(MethodName, prop) {
      console.log(MethodName, prop);
      this.$emit("Onepropemit", MethodName, prop);
    },
    CheckForPrimary(subcol, value, header, headerindex) {
      console.log(value, header);
      if (value && header) {
        if (value.Primary) {
          subcol.Headers[headerindex].Primary = value.Primary;
          console.log(subcol);
        }
      }
    },
  },
};
</script>

<style>
</style>



